import {EditorSDK} from '@wix/platform-editor-sdk'
import {EVENTS_APP_DEF_ID, EVENTS_TPA_PAGE_ID} from './constants'

export const ensureDetailsPageManaged = async (editorSDK: EditorSDK, appToken, firstInstall, t) => {
  const [{applicationId}, allPages] = await Promise.all([
    editorSDK.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID),
    editorSDK.pages.data.getAll(appToken),
  ])

  const {id, type, managingAppDefId: currentManagingAppDefId} = allPages.find(
    ({tpaApplicationId, tpaPageId}) => tpaApplicationId === applicationId && tpaPageId === EVENTS_TPA_PAGE_ID,
  )

  if (currentManagingAppDefId !== EVENTS_APP_DEF_ID) {
    const data: any = {
      managingAppDefId: EVENTS_APP_DEF_ID,
    }

    if (firstInstall) {
      data.title = t('pagesPanelEventsDetailsTitle')
    }

    // @ts-expect-error
    editorSDK.pages.data.update(appToken, {pageRef: {id, type}, data})
  }
}
