const getTranslationUrl = (staticsUrl: string, locale: string) => {
  return `${staticsUrl}assets/locale/editor-script_${locale}.json`
}

export const getTranslateFunction = async (staticsUrl: string, locale: string) => {
  const translationsJson = await (await fetch(getTranslationUrl(staticsUrl, locale))).json()
  return (keyToTranslate: string): string => {
    return translationsJson[keyToTranslate]
  }
}
