import {EVENTS_TPA_PAGE_ID} from './constants'

export const EVENT_MANAGE_EVENTS = 'manageEvents'
export const EVENT_DELETE_EVENTS = 'deleteEvents'

const handlers = {
  [EVENT_MANAGE_EVENTS]: async ({sdk, appToken}) => {
    await sdk.editor.openDashboardPanel(appToken, {url: 'events', closeOtherPanels: false})
    sdk.tpa.app.refreshApp()
  },
  [EVENT_DELETE_EVENTS]: async ({sdk, appToken}) => {
    const eventsPages = await sdk.document.pages.getApplicationPages(appToken)
    const essentialPage = eventsPages.find((page: any) => page.tpaPageId === EVENTS_TPA_PAGE_ID)
    sdk.document.pages.remove(appToken, {pageRef: {id: essentialPage.id}})
  },
}

export const onEventFactory = (getAppToken: Function) => async ({eventType, eventPayload}, editorSDK) => {
  await handlers?.[eventType]?.({sdk: editorSDK, payload: eventPayload, appToken: getAppToken()})
}
