import {
  installRegisteredApps,
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
} from '@wix/members-area-integration-kit'
import {EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import Experiments from '@wix/wix-experiments'
import {getAppManifestFactory} from './app-manifest'
import {
  BASE_INSTALL_CONFIG,
  EVENTS_APP_DEF_ID,
  EVENTS_TPA_PAGE_ID,
  PAID_PLANS_APP_DEF_ID,
  ProgressBarConfig,
  PROMO_INSTALL_CONFIG,
  PROMO_UPDATE_CONFIG,
} from './constants'
import {onEventFactory} from './events'
import {fetchExperiments, installPageEnabled, pagePanelEventsEnabled} from './experiments'
import {ensureDetailsPageManaged} from './pages-panel'
import {getTranslateFunction} from './services/translations'
import {parseStaticsUrlFromEditorScriptUrl} from './services/url'
import {waitALittle} from './services/wait-a-little'

let sdk: any
let appToken: string
let t: any
let adi: boolean
let staticsUrl: string
let locale: string
let experiments: Experiments

const EventsApp = {
  editorReady: async (editorSDK: EditorSDK, token: string, options: any): Promise<any> => {
    const [localeResponse, experimentsResponse] = await Promise.all([
      editorSDK.environment.getLocale(),
      fetchExperiments(),
    ])

    locale = localeResponse
    experiments = experimentsResponse
    sdk = editorSDK
    appToken = token
    staticsUrl = parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl)
    t = await getTranslateFunction(staticsUrl, locale)
    adi = options?.origin?.type === 'ADI'

    if (installPageEnabled(experiments)) {
      installPage({editorSDK, token})
    }

    await setAppAPI()

    if (pagePanelEventsEnabled(experiments)) {
      await ensureDetailsPageManaged(editorSDK, token, options.firstInstall, t)
    }
  },
  handleAction: async (args: any) => {
    const {type, payload} = args

    switch (type) {
      case 'appInstalled':
        switch (payload.appDefinitionId) {
          case EVENTS_APP_DEF_ID:
            if (adi) {
              return
            }
            await installMembersArea()
            return
          default:
            return
        }
      case 'migrate':
        if (payload?.addPaidPlans) {
          return addPaidPlans()
        }
        break
      default:
        console.log(type, payload)
        return
    }
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => locale,
    () => appToken,
    () => sdk,
    () => experiments,
  ),
  onEvent: onEventFactory(() => appToken),
}

const setAppAPI = async () => {
  const api: EventsApi = {
    installMembersArea: async () => installMembersArea(true),
    installMembersAreaSections,
  }
  await sdk.editor.setAppAPI(appToken, api)
}

const showProgressBar = (config: ProgressBarConfig): Promise<Function> => {
  return new Promise(async (resolve) => {
    const panelRef = await sdk.editor.openProgressBar(appToken, {
      title: t(config.title),
      totalSteps: 3,
      currentStep: 1,
      stepTitle: t(config.step1),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: 2,
      stepTitle: t(config.step2),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: 3,
      stepTitle: t(config.step3),
    })
    await waitALittle(800)
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, false, {panelRef})
    })
  })
}

const installMembersArea = async (isInstallingFromMembersPromo = false) => {
  const progressBarPromise = isInstallingFromMembersPromo
    ? showProgressBar(PROMO_INSTALL_CONFIG)
    : showProgressBar(BASE_INSTALL_CONFIG)

  await maybeInstallMembersArea()
    .then(() => hideProgressBar(progressBarPromise))
    .catch(() => hideProgressBar(progressBarPromise, false))
  // sdk.editor.openProgressBar does not always resolve
}

const hideProgressBar = (progressBarPromise: Promise<Function>, shouldAwait = true) => {
  progressBarPromise.then(async (close) => {
    if (shouldAwait) {
      await waitALittle(2000)
    }
    close()
  })
}

const installMembersAreaSections = async () => {
  const progressBarPromise = showProgressBar(PROMO_UPDATE_CONFIG)
  await installRegisteredApps()
  progressBarPromise.then(async (close) => {
    await waitALittle(1500)
    close()
  })
}

const addPaidPlans = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: PAID_PLANS_APP_DEF_ID})
}

const installPage = async ({editorSDK, token}: {editorSDK: EditorSDK, token: string}) => {
  const pageInstalled = await editorSDK.tpa.isAppSectionInstalled(token, {
    sectionId: EVENTS_TPA_PAGE_ID,
    appDefinitionId: EVENTS_APP_DEF_ID,
  })
  if (!pageInstalled) {
    await editorSDK.tpa.add.component(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
      componentType: TPAComponentType.Page,
      page: {
        pageId: EVENTS_TPA_PAGE_ID,
        isHidden: true,
      },
    })
    return editorSDK.editor.save(token)
  }
}

const editorApp = withMembersArea(EventsApp, {
  installAutomatically: false,
  membersAreaApps: [MA_APP_IDS.ABOUT, MA_APP_IDS.MY_EVENTS, MA_APP_IDS.MY_WALLET],
})

export const editorReady = editorApp.editorReady
export const handleAction = editorApp.handleAction
export const onEvent = editorApp.onEvent
export const getAppManifest = editorApp.getAppManifest
